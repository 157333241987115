:root {
  --container-max-width: 600px;
  --capture-border-width: 3px;
  --capture-border-radius: 6px;
  --video-border-radius: calc(var(--capture-border-radius) / 2);
  --scan-frame-color: rgba(255, 255, 255, 0.9);
  --error-color: #dc3545;
  --success-color: #28a745;

  accent-color: var(--accent);
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body:has(.modal[open]) {
  overflow-y: hidden;
}

.noscript {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  text-align: center;
  font-size: 0.9rem;
  color: #842029;
  background-color: #f8d7da;
}

[hidden] {
  display: none !important;
}

.site-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2rem 0;
  text-align: center;
}

h1 {
  margin: 0.75rem 0;
  font-size: 1.6rem;
  font-weight: 300;
}

.container {
  max-width: var(--container-max-width);
  margin: 0 auto;
}

.toastContainer {
  width: 100%;
  padding: 0 0.5rem;
  z-index: 1051;
  pointer-events: none;
}

.alert {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  padding: 0.5rem 1rem;
  margin: 0 0 1rem 0;
  font-size: 0.95rem;
}

.alert button {
  width: 35px;
  min-width: 35px;
  margin: 0;
  padding: 0.15rem;
  background-color: transparent;
  z-index: 2;
  color: inherit;
  font-size: 1.2rem;
  line-height: 1;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border: 1px solid #f5c2c7;
  border-radius: 0.25rem;
}

.toastContainer .alert {
  max-width: var(--container-max-width);
  margin: 0.5rem auto 1rem auto;
  padding-right: 0;
  pointer-events: all;
}

.fork {
  display: inline-flex;
  align-items: center;
  gap: 0.375rem;
}

.scan-button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0.5rem;
  border-radius: 0;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.7) !important;
  backdrop-filter: blur(6px);
  font-family: var(--font-family);
  font-size: 0.9rem;
}

.results {
  position: relative;
  width: 100%;
  border: 1px solid var(--focus);
  border-radius: 6px;
  margin: 1.5rem 0 0 0;
  padding: 1rem;
  background-color: var(--background-alt);
}

.results__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word;
}

.results__item--no-barcode {
  color: var(--error-color);
}

.results__actions {
  display: flex;
  align-items: center;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
}

.results web-share button,
.results custom-clipboard-copy::part(button) {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  margin: 0.75rem 0 0 0;
  padding: 0.25rem;
  background-color: transparent;
  border: 0;
  border-radius: var(--border-radius);
  color: inherit;
  line-height: 1;
  font-family: inherit;
  font-size: 0.9rem;
  cursor: pointer;
}

custom-clipboard-copy::part(button):focus-visible {
  outline: 0;
  box-shadow: 0 0 0 2px var(--focus);
}

.scan-instructions {
  text-align: center;
}

.scan-instructions p {
  max-width: 320px;
  margin: 0 auto;
  font-size: 0.95rem;
}

.scan-frame-container {
  position: relative;
  max-width: var(--container-max-width);
  margin: 0 auto;
}

.scan-frame-container:has(capture-photo[loading]) .scan-frame {
  display: none;
}

.scan-frame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9);
  pointer-events: none;
}

.scan-frame svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

select[name="scanMethod"] {
  width: 100%;
  margin: 0 0 1.5rem 0;
}

input[type="file"]::-webkit-file-upload-button {
  color: var(--background);
  background-color: var(--accent);
  border: 0;
  border-radius: 0.25rem;
  padding-inline: 0.5rem;
  margin-right: 0.5rem;
  line-height: 1.6;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
}

input[type="file"]:disabled::-webkit-file-upload-button {
  cursor: not-allowed;
}

.dropzone {
  --dropzone-focus-box-shadow: inset 0 0 0 2px var(--focus);
  --dropzone-transition-duration: var(--animation-duration);
  --dropzone-border-color: var(--border);
  --dropzone-border-color-dragover: var(--accent);
  --dropzone-border-color-hover: var(--accent);
  --dropzone-background-color: var(--background-alt);
  --dropzone-background-color-dragover: var(--background);
  --dropzone-background-color-hover: var(--background);
  --dropzone-body-color: var(--text-main);
}

.dropzone::part(dropzone) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  min-height: 250px;
  border-radius: 6px;
}

.dropzone-preview {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.dropzone-preview__image-wrapper {
  max-width: 200px;
  margin: 0 auto;
}

.dropzone-preview__file-name {
  font-size: 0.9rem;
  color: var(--text-main);
}

.dropzone-instructions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
}

body > footer {
  margin-top: 1.5rem;
  text-align: center;
}

.supported-formats:empty {
  display: none;
}

.supported-formats {
  max-width: var(--container-max-width);
  margin: 1rem auto 0 auto;
  text-align: center;
  padding: 0 0.75rem;
  font-size: 0.75rem;
  color: var(--text-muted);
}

capture-photo {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 auto 1.5rem auto;
  overflow: hidden;
  border: var(--capture-border-width) solid var(--border);
  border-radius: var(--capture-border-radius);
  background-color: #000000;
}

capture-photo::part(video) {
  width: 100%;
  height: calc(100% - 10px);
  border-radius: var(--video-border-radius);
  background-color: #000000;
}

capture-photo[loading]::part(video) {
  background-image: url(../assets/icons/spinner-light.svg);
  background-size: 60px;
  background-position: center center;
  background-repeat: no-repeat;
}

capture-photo::part(facing-mode-button) {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0.25rem;
  left: 0.25rem;
  width: 36px;
  height: 36px;
  padding: 0;
  border: 0;
  border-radius: var(--video-border-radius);
  background-color: var(--background);
  color: var(--text-main);
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  transition: background-color 0.15s ease-in-out;
}

.zoom-controls {
  position: absolute;
  bottom: 0.25rem;
  right: 0.25rem;
  display: flex;
}

.zoom-controls button,
.zoom-controls label {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 36px;
  height: 36px;
  padding: 0;
  border-radius: 0;
  background-color: var(--background);
  color: var(--text-main);
}

.zoom-controls button[data-action="zoom-in"] {
  border-top-right-radius: var(--video-border-radius);
  border-bottom-right-radius: var(--video-border-radius);
}

.zoom-controls button[data-action="zoom-out"] {
  border-top-left-radius: var(--video-border-radius);
  border-bottom-left-radius: var(--video-border-radius);
}

.zoom-controls label {
  width: 25px;
  font-size: 0.9rem;
  overflow: hidden;
  font-family: Arial, Helvetica, sans-serif;
}

.global-actions {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.global-actions button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.35rem;
  padding: 0;
  background-color: transparent !important;
  color: var(--text-main);
  line-height: 1;
  padding: 8px;
  margin: 0;
  font-size: 0.9rem;
}

.global-actions button svg {
  width: 18px;
  height: 18px;
}

.global-actions button span {
  display: none;
}

.modal > header:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0.5rem 0.75rem;
  font-weight: 500;
}

.modal > header:first-child button {
  padding: 0.25rem;
  margin: 0;
  background-color: transparent !important;
  line-height: 1;
  color: var(--text-main);
}

.modal {
  position: absolute;
  top: 16px;
  right: 16px;
  left: auto;
  padding: 0;
  margin: 0;
}

.modal :not(header) {
  font-size: 0.9rem;
}

.modal ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.modal ul li {
  position: relative;
  display: flex;
  align-items: center;
  color: var(--text-main);
  text-decoration: none;
  border-bottom: 1px solid var(--border);

}

.history-modal ul {
  max-width: 500px;
}

.history-modal ul li {
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 0.75rem;
  word-break: break-all;
}

.history-modal__actions {
  display: flex;
  gap: 0.25rem;
}

.history-modal__actions button,
.history-modal__actions custom-clipboard-copy::part(button) {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  padding: 0.25rem 0.5rem;
  margin: 0;
  border: 0;
  border-radius: var(--border-radius);
  background-color: transparent !important;
  line-height: 1;
  color: var(--text-main);
  cursor: pointer;
}

.history-modal__actions custom-clipboard-copy::part(button--success) {
  color: var(--success-color);
}

.history-modal__actions .history-modal__delete-action {
  color: var(--error-color);
  margin-right: -0.5rem;
}

.settings-modal ul li input[type="checkbox"] {
  min-width: 16px;
  margin: 0.15rem 0.5rem 0 0;
}

.settings-modal ul li label {
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0.75rem;
  cursor: pointer;
}

.settings-modal fieldset {
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
}

.settings-modal fieldset legend {
  padding: 0.5rem 0.75rem 0 0.75rem;
  font-weight: 500;
}

#deleteHistoryBtn {
  width: calc(100% - 1.5rem);
  margin: 0.75rem 0 0.75rem 0.75rem;
  background-color: var(--error-color) !important;
  color: #ffffff !important;
}

/* Tab group */
a-tab-group:not(:defined) {
  display: none;
}

a-tab-group::part(tabs) {
  border-radius: var(--border-radius);
  padding: 0;
  overflow: hidden;
}

a-tab {
  display: flex;
  flex: 1;
}

a-tab::part(base) {
  flex: 1;
  justify-content: center;
  padding: 0.5rem 0.75rem;
  background-color: var(--background);
  transition: background-color var(--animation-duration) ease-in-out, color var(--animation-duration) ease-in-out;
}

a-tab:focus-visible {
  outline: none;
  box-shadow: inset 0 0 0 2px var(--focus);
}

a-tab[selected]::part(base) {
  background-color: var(--accent);
  color: var(--background-body);
}

/* Utils */
.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

@media (prefers-color-scheme: dark) {
  :root {
    --error-color: #e05260;
    --success-color: #75b798;
  }

  .dropzone {
    --dropzone-background-color: #1a1c1e;
    --dropzone-background-color-dragover: #161718;
    --dropzone-background-color-hover: #161718;
  }
}

@media (min-width: 600px) {
  h1 {
    font-size: 2rem;
  }

  .scan-instructions p {
    max-width: none;
  }

  capture-photo {
    max-width: var(--container-max-width);
  }

  .global-actions button span {
    display: inline;
  }
}

@media (min-height: 700px) {
  header {
    margin: 3rem 0;
  }
}

@media screen and (max-width: 320px) {
  a-tab span {
    font-size: 0.9rem;
  }

  a-tab svg {
    display: none;
  }
}
